import React from 'react'
import Layout from '../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import COLORS from '../constants/colors'
import NewsCard from '../components/NewsCard'
import SubPageHeroBanner from '../components/SubPageHeroBanner'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  newsContainer: {
    marginTop: 60,
    backgroundColor: COLORS.WHITE,
    padding: '20px 160px 40px 160px',
    '@media (max-width:1024px)': {
      padding: '20px 100px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 50px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      marginTop: 0,
    },
  },
  newsCardContainer: {
    padding: 16,
    marginTop: '20px',
  },
})

const News = () => {
  const data = useStaticQuery(query)
  const classes = useStyles()
  return (
    <Layout>
      <SubPageHeroBanner
        textPosition="Left"
        image={data.news.childImageSharp.fluid}
        title="News"
      />
      <div className={classes.root}>
        <Grid container justify="flex-start" className={classes.newsContainer}>
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <Grid
              key={node.slug}
              item
              sm={4}
              className={classes.newsCardContainer}
            >
              <NewsCard data={node} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Layout>
  )
}

export default News

export const query = graphql`
  query NewsQuery {
    news: file(relativePath: { eq: "sub_hero_news.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(quality: 90, maxWidth: 300, maxHeight: 200) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`
